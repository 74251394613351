import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import Vimeo from '@u-wave/react-vimeo';
import { DateTime } from 'luxon';
import { formatDates } from '../utility';
import Play from '../svg/play.svg';

export default class OnlineCard extends React.Component {
  constructor(props) {
    super();

    this.people = props.program.people.filter(person => person);
    this.player = null;

    this.state = {
      hideImage: false,
    }

    this.tags = (props.program.location) ? [props.program.location.charAt(0).toUpperCase() + props.program.location.slice(1)] : [];
    this.tags = this.tags.concat(props.program.type.map((el) => el.title));
    this.tags = this.tags.concat(props.program.series.map((el) => el.title));
  }

  render() {
    return (
      <Link
        to={`/program/${this.props.program.slug.current}`}
        css={css`
          display: block;
          align-items: center;
          justify-content: center;
          position: relative;
          text-align: center;
          text-decoration: none;
          border-radius: 6rem;

          &:hover {
            opacity: 1;

            img {
              mix-blend-mode: screen;
            }
          }
        `}
        className={this.props.className}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          {(this.props.program.featureImage && !this.props.program.featureVideo) &&
            <div
              css={css`
                background-color: var(--pink);
                border-radius: 6rem;
                line-height: 0;
              `}
            >
              <Image
                {...this.props.program.featureImage}
                css={css`
                  background-color: var(--pink);
                  border-radius: ${(this.props.program.location === 'online') ? `6rem` : `0`};
                `}
                width={990}
                height={620}
                style={{
                  width: '100%',
                }}
                alt={(this.props.program.featureImage.asset.altText) ? this.props.program.featureImage.asset.altText : this.props.program.title}
              />
            </div>
          }
          {this.props.program.featureVideo &&
            <div
              css={css`
                position: relative;
                border-radius: 6rem;
                z-index: 1;

                &:hover {
                  svg path {
                    fill: var(--black);
                  }
                }

                iframe {
                  border-radius: 6rem;
                }
              `}
            >
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  background-image: url(${this.props.program.featureImage.asset.gatsbyImageData.images.fallback.srcSet.split(' ').reverse()[1].split('\n')[1]});
                  background-size: cover;
                  border-radius: 6rem;
                  opacity: ${(this.state.hideImage) ? 0 : 1};
                  z-index: 1;
                  cursor: pointer;
                `}
                onClick={(e) => {
                  e.preventDefault();
                  this.player?.getPaused().then((result) => {
                    if (result) {
                      this.player?.play();
                    } else {
                      this.player?.pause();
                    }
                  });

                  this.setState({
                    hideImage: !this.state.hideImage,
                  });
                }}
              ></div>
              <Vimeo
                video={this.props.program.featureVideo}
                controls={false}
                onReady={(player) => this.player = player}
                onPause={(player) => this.setState({ hideImage: false })}
                color="D8B0B0"
                showPortrait={false}
                showTitle={false}
                showByline={false}
                responsive
              />
              <div
                css={css`
                  display: ${(this.state.hideImage) ? 'none' : 'block'};
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  pointer-events: none;
                  z-index: 2;

                  @media (max-width: 700px) {
                    svg {
                      width: 50px;
                    }
                  }

                  svg path {
                    fill: var(--white);
                  }
                `}
              >
                <Play />
              </div>
            </div>
          }
          {!this.props.program.featureImage &&
            <div
              css={css`
                padding-bottom: 62.5%;
                background-color: var(--20Brown);
              `}
            />
          }
          <div
            className="type--outline--small"
            css={css`
              display: ${(this.props.showImages) ? 'block' : 'none'};
              position: absolute;
              right: var(--gutter);
              bottom: var(--gutter);
              left: var(--gutter);
            `}
          >
            {(this.props.program?.featureImage?.asset.description) ? this.props.program.featureImage.asset.description : this.props.program.title}
          </div>
        </div>
        <div
          css={css`
            display: ${(!this.props.showImages) ? 'block' : 'none'}
          `}
        >
          <h3
            className="type--large"
            css={css`
              margin-top: 4rem;
              margin-bottom: 0;
            `}
          >{formatDates(this.props.program.startDate, this.props.program.endDate)}</h3>
          <h2
            className="type--large"
            css={css`
              margin-bottom: 4.5rem;
            `}
          >{this.props.program.title}</h2>
          <h3
            className="type--large"
            css={css`
              margin-bottom: 5rem;
            `}
          >
            {this.people.length > 8 && <span>Various Artists</span>}
            {(this.people.length > 0 && this.people.length < 8) && this.people
              .map(person => <span key={person.id}>{person.name}</span>)
              .reduce((prev, curr) => [prev, ', ', curr])
            }
          </h3>
          <div
            className="type--mono"
            css={css`
              position: static;
              width: 100%;
              margin: 1.1rem 0 1.3rem;
            `}
          >
            {this.tags.length > 0 && this.tags
              .map((tag, i) => <span key={i}>{tag}</span>)
              .reduce((prev, curr) => [prev, ', ', curr])
            }
          </div>
        </div>
      </Link>
    );
  }
}
